<template>
  <div>
    <TikTokLogo/>
    <div v-if="isAuthorized"/>
      <div class="container">
        <div class="left-column">
          <h1 class="title">Vender com TikTok</h1>
          <h1 class="subtitle">ficou fácil!</h1>
          <p class="resumo">Crie anúncios em formato de vídeo para seu público alvo,
            vinculando os produtos de sua loja de forma fácil e rápida,
            através do catálogo de produtos ativos de sua loja.
          </p>
          <h3>Configuração fácil e rápida!</h3>
          <div class="steps">
            <p>
              <span class="step-number">1.</span>
              <span class="step-text">
              Clique em "Começar" para <b>criar sua conta</b>
              e/ou <b>fazer o login</b> em sua conta do <b>TikTok Business</b>.
            </span>
            </p>
            <p>
              <span class="step-number">2.</span>
              <span class="step-text"><b>Escolha</b> e <b>habilite</b> a conta do <b>TikTok Ads Manager</b>.</span>
            </p>
            <p>
              <span class="step-number">3.</span>
              <span class="step-text"><b>Envie</b> o <b>catálogo de produtos ativos</b> que poderão ser anunciados.</span>
            </p>
          </div>
          <div class="ready-text">Pronto!</div>
        </div>
        <div class="right-column">
          <img src="../assets/img/landing-page.svg">
          <div class="right-content">
            <h3>Catálogo de produtos integrado!</h3>
            <p>Concluindo as configurações, você poderá:</p>
            <ul>
              <li>Realizar a publicidade de seus produtos no TikTok.</li>
              <li>Redirecionar os clientes do TikTok diretamente para as páginas dos produtos anunciados.</li>
              <li>Acompanhar o desempenho de suas campanhas.</li>
            </ul>
            <div class="button-start-now">
              <b-button variant="primary" v-on:click="start()">Começar agora</b-button>
            </div>
          </div>
        </div>
        <alert-error v-if="error">
          <template v-slot:alert-error>
            {{msgError}}
          </template>
        </alert-error>
      </div>
    </div>
</template>

<script>
// @ is an alias to /src
import TikTokLogo from "@/components/TikTokLogo";

export default {
  name: 'LandingPage',
  components: {
    TikTokLogo
  },
  beforeMount() {
    document.title = 'Integrar loja Tray com TikTok'

    let authorization = true; //this.$cookies.get('Authorization')

    if (undefined === authorization || authorization.length === 0) {
      window.location = 'https://www.tray.com.br'
    }

    this.authorization = authorization
  },
  data: function () {
    return {
      authorization: null,
      error: false,
      msgError: ""
    }
  },
  computed: {
    isAuthorized: function () {
      return null !== this.authorization
    },
  },
  methods: {
    start() {
     let url_key = this.$route.query.url+'&consumer_key='+this.$route.query.consumer_key+'&callback='+this.$route.query.callback; 
      window.location = url_key;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.left-column, div.right-column {
  flex: 50%;
  padding: 2rem 2.5rem;
}

div.left-column {
  padding-top: 5rem;
}

div.right-content {
  background-color: #F6F6F6;
  padding-bottom: 3rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 200px;
  margin-top: -190px;
}


h1.title {
  color: #43A0D6;
  font-weight: 600;
  margin-bottom: 0;
}

h1.subtitle {
  font-weight: 200;
}

h3 {
  font-size: 1.5rem;
  color: #43A0D6;
  font-weight: 600;
  margin-bottom: 2rem;
}

p.resumo {
  margin-bottom: 5.5rem;
  margin-top: 2.5rem;
  text-align: justify;
}

div.steps {
  margin-top: 2rem;
}

div.steps p {
  display: flex;
}

span.step-number {
  background-color: #43A0D6;
  border-radius: 0.30rem;
  color: #FFFFFF;
  font-size: 2.5rem;
  font-weight: 900;
  height: 1.3em;
  margin-left: 1rem;
  margin-right: 1.3rem;
  padding: 0rem 0.4rem 0rem 0.6rem;
}

span.step-text i {
  font-style: normal;
  font-weight: 600;
}

div.ready-text {
  color: #43A0D6;
  font-weight: 900;
  font-size: 2rem;
  letter-spacing: -1px;
  margin-left: 1.5rem;
}

ul {
  line-height: 2rem;
  list-style: none;
  padding-left: 1.5rem;
}

ul li::before {
  content: "\2022";
  color: #43A0D6;
  font-weight: bold;
  display: inline-block;
  width: 1rem;
  margin-left: -1rem;
}

div.button-start-now {
  display: flex;
  justify-content: center;
}
/*
button {
  border-radius: 0.75rem;
  font-weight: 600;
  width: 100%;
  padding: 0.5rem;
}*/
</style>
