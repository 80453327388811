import { XptoInterface } from './contracts/xpto-interface';

export class Xpto implements XptoInterface {
  getProducts(): Array<any> {
    return [];
  }

  getVersion(): string {
    return '1.0.0';
  }

  getCustomer(): Array<any> {
    return [];
  }
}
