
import Vue from 'vue';
import Component from 'vue-class-component';
import { Xpto } from '../components/xpto';

@Component
class Home extends Vue {
  products: Array<any> = [];

  version!: string;

  initialized = false;

  mounted(): void {
    const xpto = new Xpto();
    this.version = xpto.getVersion();
    this.products = xpto.getProducts();
    this.initialized = true;
  }
}

export default Home;
